// 
// Extras pages.scss
//


// Directory page

.social-links {
    li {
      a {
        background: lighten($light,4%);
        border-radius: 50%;
        color: lighten($dark,15%);
        display: inline-block;
        height: 30px;
        line-height: 30px;
        text-align: center;
        width: 30px;
      }
    }
  }

  // Authentication
  .auth-body-bg{
    background-image: url(../images/bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .bg-overlay {
      background: rgba(51, 51, 51, 0.97);
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0px;
      top: 0px;
    }
  }

  

  .wrapper-page {
    margin: 7.5% auto;
    max-width: 460px;
    position: relative;
  
    // .logo-admin {
    //   font-size: 28px;
    //   line-height: 70px;
    // }
    .auth-logo{
      font-size: 28px;
      line-height: 70px;
      &.logo-light{
        display: $display-none;
      }
      &.logo-dark {
        display: $display-block;
      }
    }
  }
  // Error Page

  .ex-page-content {
    h1 {
      font-size: 98px;
      font-weight: 700;
      line-height: 150px;
      text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;
    }
  }
  
